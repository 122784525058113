import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Emitter from "../components/Emitter";
import { getStream } from "../speech/Microphone";
import { timeNow } from "../utils/Utils";

export default class Transcription {
    constructor(eventName, addMessage, counter) {
        this.eventName = eventName;
        this.recognizer = "";
        this.language = "";
        this.channel = null;
        this.addMessage = addMessage;
        this.counter = counter;
    }

    start() {
        Emitter.on(this.eventName, (data) => this.startTranscription(data.language, data.channel));
        Emitter.on("STOP_TRANSCRIPTION", () => this.stopTranscription());
    }

    stop() {
        Emitter.off(this.eventName);
        Emitter.off("STOP_TRANSCRIPTION");
    }

    startTranscription(language, channel) {
        this.language = language;
        this.channel = channel;

        if (!navigator.getUserMedia)
            navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;

        var constraints = {
            audio: {
                echoCancellation: false,
                noiseSuppression: false,
                autoGainControl: false
            }
        }

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia){
            navigator.mediaDevices.getUserMedia(constraints)
                .then(this.handleAttachStream.bind(this))
                .catch(this.handleError.bind(this));
        }
    }

    handleAttachStream = (stream) => {
        const streamData = getStream(stream, this.channel);
        const channelStream = streamData[0];
        const audioContext = streamData[1]; 

        const cognitiveServicesSdk = require("microsoft-cognitiveservices-speech-sdk");
        const speechConfig = cognitiveServicesSdk.SpeechConfig.fromSubscription(process.env.REACT_APP_API_KEY, process.env.REACT_APP_REGION);

        this.transcription = "";

        //let audioConfig = cognitiveServicesSdk.AudioConfig.fromDefaultMicrophoneInput();
        let audioConfig = cognitiveServicesSdk.AudioConfig.fromStreamInput(channelStream);
        speechConfig.speechRecognitionLanguage = this.language;
        const recognizer = new cognitiveServicesSdk.SpeechRecognizer(speechConfig, audioConfig);

        // Signal for events containing intermediate recognition results
        recognizer.recognizing = (s,e) => {
            console.log(`RECOGNIZING: Text=${e.result.text}`);
            this.counter.incrementCounter();
        }

        // Signal for events containing final recognition results
        recognizer.recognized = (s,e) => {
            if (e.result.reason === cognitiveServicesSdk.ResultReason.RecognizedSpeech) {
                console.log(`RECOGNIZED: Text=${e.result.text}`);
                if (e.result.text !== "") {
                    this.addMessage(e.result.text, timeNow());
                }
            }
            else if (e.result.reason === cognitiveServicesSdk.ResultReason.NoMatch) {
                console.log("NOMATCH: Speech could not be recognized.");
            }
            this.counter.incrementCounter();
        }

        // Signal for events containing canceled recognition results (indicating a recognition attempt that was canceled 
        // as a result or a direct cancellation request or, alternatively, a transport or protocol failure).
        recognizer.canceled = (s, e) => {
            console.log(`CANCELED: Reason=${e.reason}`);
        
            if (e.reason === cognitiveServicesSdk.CancellationReason.Error) {
                console.log(`"CANCELED: ErrorCode=${e.errorCode}`);
                console.log(`"CANCELED: ErrorDetails=${e.errorDetails}`);
                console.log("CANCELED: Did you update the subscription info?");
            }
        
            recognizer.stopContinuousRecognitionAsync();
        };

        // Signal for events indicating the end of a recognition session
        recognizer.sessionStopped = (s, e) => {
            console.log("\n    Session stopped event.");
            recognizer.stopContinuousRecognitionAsync();
            recognizer.close();
            audioConfig.turnOff();
            audioConfig.close();
            channelStream.close();
            audioContext.close();
        };

        // Start Transcription
        recognizer.startContinuousRecognitionAsync();

        this.recognizer = recognizer;
    }

    handleError = (error) => {
        toast.error(error, {
            position: toast.POSITION.BOTTOM_RIGHT
          });
    }

    stopTranscription() {
        if (this.recognizer !== null) {
            this.recognizer.stopContinuousRecognitionAsync();
            this.recognizer.close();
        }
    }
}