import React from "react";
import { Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import NotFound from "./containers/NotFound";
import Chat from "./containers/Chat";
import About from "./containers/About";

export default function Routes() {
  return (
    <Switch>
        <AppliedRoute path="/" exact component={Chat}/>
        <AppliedRoute path="/about" exact component={About}/>
        {/* Finally, catch all unmatched routes */}
        <AppliedRoute component={NotFound}/>
    </Switch>
  );
}