import React from "react";
import Message from "./Message";
import "./ChatBox.css";

export default class ChatBox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            messages: this.props.messages
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            this.setState({
                messages: this.props.messages
            });
        }
    }

    render() {
        return (
            <div className="main-box" id={this.props.id}>
                {this.state.messages.map((msg, index) => {
                    // Check if the previous message is part of the same conversation block
                    var sameConversation = false;
                    if (index > 0) {
                        const prev = this.state.messages[index-1];
                        if (prev.position === msg.position && prev.time === msg.time) {
                            sameConversation = true;
                        }
                    }

                    return (
                        <Message message={msg} key={index} sameConversation={sameConversation}/>
                    )
                })
                }
            </div>
        );
    }
}