import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Emitter from "../components/Emitter";
import { getStream } from "../speech/Microphone";
import { timeNow } from "../utils/Utils";

export default class Translation {
    constructor(eventName, addMessage, counter) {
        this.eventName = eventName;
        this.translator = null;
        this.sourceLanguage = "";
        this.targetLanguage = "";
        this.channel = null;
        this.addMessage = addMessage;
        this.counter = counter;
    }

    start() {
        Emitter.on(this.eventName, (data) => this.startTranslation(data.source, data.target, data.channel));
        Emitter.on("STOP_TRANSLATION", () => this.stopTranslation());
    }

    stop() {
        Emitter.off(this.eventName);
        Emitter.off("STOP_TRANSLATION");
    }

    startTranslation(sourceLanguage, targetLanguage, channel) {
        this.sourceLanguage = sourceLanguage;
        this.targetLanguage = targetLanguage;
        this.channel = channel;

        if (!navigator.getUserMedia)
            navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;

        var constraints = {
            audio: {
                echoCancellation: false,
                noiseSuppression: false,
                autoGainControl: false
            }
        }

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia){
            navigator.mediaDevices.getUserMedia(constraints)
                .then(this.handleAttachStream.bind(this))
                .catch(this.handleError.bind(this));
        }
    }

    handleAttachStream = (stream) => {
        const streamData = getStream(stream, this.channel);
        const channelStream = streamData[0];
        const audioContext = streamData[1]; 

        const cognitiveServicesSdk = require("microsoft-cognitiveservices-speech-sdk");
        const speechConfig = cognitiveServicesSdk.SpeechTranslationConfig.fromSubscription(process.env.REACT_APP_API_KEY, process.env.REACT_APP_REGION);

        let audioConfig = cognitiveServicesSdk.AudioConfig.fromStreamInput(channelStream);
        speechConfig.speechRecognitionLanguage = this.sourceLanguage;
        speechConfig.addTargetLanguage(this.targetLanguage);

        const translator = new cognitiveServicesSdk.TranslationRecognizer(speechConfig, audioConfig);

        // Signal for events containing intermediate recognition results
        translator.recognizing = (s,e) => {
            console.log(`RECOGNIZING: Text=${e.result.text}`);
            this.counter.incrementCounter();
        }

        translator.recognized = (s,e) => {
            console.log(e.result.reason);
            console.log(e);
            if (e.result.reason === cognitiveServicesSdk.ResultReason.TranslatedSpeech) {
                console.log(`RECOGNIZED: Text=${e.result.translations.get(this.targetLanguage)}`);
                var text = e.result.translations.get(this.targetLanguage);
                if (text !== "") {
                    this.addMessage(text, timeNow());
                }
            }
            else if (e.result.reason === cognitiveServicesSdk.ResultReason.NoMatch) {
                console.log("NOMATCH: Speech could not be recognized.");
            }
            this.counter.incrementCounter();
        }

        // Signal for events containing canceled recognition results (indicating a recognition attempt that was canceled 
        // as a result or a direct cancellation request or, alternatively, a transport or protocol failure).
        translator.canceled = (s, e) => {
            console.log(`CANCELED: Reason=${e.reason}`);
        
            if (e.reason === cognitiveServicesSdk.CancellationReason.Error) {
                console.log(`"CANCELED: ErrorCode=${e.errorCode}`);
                console.log(`"CANCELED: ErrorDetails=${e.errorDetails}`);
                console.log("CANCELED: Did you update the subscription info?");
            }
        
            translator.stopContinuousRecognitionAsync();
        };

        // Signal for events indicating the end of a recognition session
        translator.sessionStopped = (s, e) => {
            console.log("\n    Session stopped event.");
            translator.stopContinuousRecognitionAsync();
            translator.close();
            audioConfig.turnOff();
            audioConfig.close();
            channelStream.close();
            audioContext.close();
        };

        translator.startContinuousRecognitionAsync();

        this.translator = translator
    }

    handleError = (error) => {
        toast.error(error, {
            position: toast.POSITION.BOTTOM_RIGHT
          });
    }

    stopTranslation() {
        console.log("stopTranslation");
        if (this.translator !== null) {
            this.translator.stopContinuousRecognitionAsync();
        }
    }
}