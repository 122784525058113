import { Document, Paragraph, HeadingLevel, Table, TableRow, TableCell, Packer } from "docx";
import { saveAs } from "file-saver";

export default class Transcript {
    constructor() {
        this.doc = new Document();
    }

    generate(leftlanguage, rightLanguage, leftMessages, rightMessages) {
        const title = this.buildTitle();
        const date = this.buildDate();
        const table = this.buildTable(leftlanguage, rightLanguage, leftMessages, rightMessages);

        this.doc.addSection({
            children: [title, date, table],
        });
    }

    buildTitle() {
        return new Paragraph({
            text: "Conversation transcript",
            heading: HeadingLevel.HEADING_1,
        });
    }

    buildDate() {
        return new Paragraph({
            text: "Date: " + new Date().toISOString().split('T')[0],
            heading: HeadingLevel.HEADING_3,
        });
    }

    buildTable(leftlanguage, rightLanguage, leftMessages, rightMessages) {
        const headers = this.buildHeader(leftlanguage, rightLanguage);
        const rows = this.buildRows(leftMessages, rightMessages);
        const table = new Table({
            rows: [headers, rows],
            width: 0,
            columnWidths: [3213, 3213, 3212]
        });

        return table;
    }

    buildHeader(leftlanguage, rightLanguage) {
        return new TableRow({
            children: [
                new TableCell({
                    children: [new Paragraph(leftlanguage)],
                }),
                new TableCell({
                    children: [new Paragraph(rightLanguage)],
                }),
            ]
        });
    }

    buildRows(leftMessages, rightMessages) {
        return new TableRow({
            children: [
                new TableCell({
                    children: this.buildConversation(leftMessages)
                }),
                new TableCell({
                    children: this.buildConversation(rightMessages)
                }),
            ]
        })
    }

    buildConversation(messages) {
        var paragraphs = [];

        console.log(messages);

        for (const m of messages) {
            const p = new Paragraph(m.time + ": " + m.author + " -> " + m.text);
            paragraphs.push(p);
        }

        return paragraphs;
    }

    saveToFile(filename) {
        const mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

        Packer.toBlob(this.doc).then(blob => {
            const docblob = blob.slice(0, blob.size, mimeType)
            saveAs(docblob, filename);
        });
    }
}