import {React, useState} from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Routes from "./Routes.js";
import "./App.css";

function App() {
  const [isLightTheme, setLightTheme] = useState(true);

  function handleSelect(e, event) {
    event.preventDefault()

    if (isLightTheme) {
      document.getElementById('leftChat').style.setProperty("--background-color", "black");
      document.getElementById('rightChat').style.setProperty("--background-color", "black");
    } else {
      document.getElementById('leftChat').style.setProperty("--background-color", "#f3f2f1");
      document.getElementById('rightChat').style.setProperty("--background-color", "#f3f2f1");
    }

    setLightTheme(!isLightTheme);
  }

  return (
    <div className="App py-3">
      <Navbar collapseOnSelect bg="light" expand="md" className="mb-3">
        <Navbar.Brand className="font-weight-bold text-muted">
          <img 
            src="https://basecoat.cdn.dimensiondata.com/latest/img/logo-ntt.svg" 
            height="24"
            alt="Engineered by Sébastien Schmidt :)"
            className="d-inline-block align-top"
          />{' '}
        </Navbar.Brand>
        <Navbar.Toggle/>
        <Nav className="mr-auto">
            <Nav.Link as={Link} to="/">Intelligent Microphone</Nav.Link>
        </Nav>
        <Nav>
          <NavDropdown title="Settings" id="collasible-nav-dropdown">
            <NavDropdown.Item eventKey="1" onSelect={(e, event) => handleSelect(e, event)} active={isLightTheme}>Light Theme</NavDropdown.Item>
            <NavDropdown.Item eventKey="2" onSelect={(e, event) => handleSelect(e, event)} active={!isLightTheme}>Dark Theme</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link as={Link} to="/about">About</Nav.Link>
        </Nav>
      </Navbar>
      <Routes/>
      <ToastContainer />
    </div>
  );
}

export default App;