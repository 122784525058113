import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

export default class LanguageSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            languages: [
                {
                    name: "Arabic (Bahrain)",
                    transcriptionValue: "ar-BH",
                    translationValue: "ar",
                    voice: null
                },
                {
                    name: "Arabic (Egypt)",
                    transcriptionValue: "ar-EG",
                    translationValue: "ar",
                    voice: "ar-EG-ShakirNeural"
                },
                {
                    name: "Arabic (Iraq)",
                    transcriptionValue: "ar-IQ",
                    translationValue: "ar",
                    voice: null
                },
                {
                    name: "Arabic (Israel)",
                    transcriptionValue: "ar-IL",
                    translationValue: "ar",
                    voice: null
                },
                {
                    name: "Arabic (Jordan)",
                    transcriptionValue: "ar-LB",
                    translationValue: "ar",
                    voice: null
                },
                {
                    name: "Arabic (Kuwait)",
                    transcriptionValue: "ar-KW",
                    translationValue: "ar",
                    voice: null
                },
                {
                    name: "Arabic (Lebanon)",
                    transcriptionValue: "ar-LB",
                    translationValue: "ar",
                    voice: null
                },
                {
                    name: "Arabic (Oman)",
                    transcriptionValue: "ar-OM",
                    translationValue: "ar",
                    voice: null
                },
                {
                    name: "Arabic (Qatar)",
                    transcriptionValue: "ar-QA",
                    translationValue: "ar",
                    voice: null
                },
                {
                    name: "Arabic (Saudi Arabia)",
                    transcriptionValue: "ar-SA",
                    translationValue: "ar",
                    voice: "ar-SA-HamedNeural"
                },
                {
                    name: "Arabic (State of Palestine)",
                    transcriptionValue: "ar-PS",
                    translationValue: "ar",
                    voice: null
                },
                {
                    name: "Arabic (Syria)",
                    transcriptionValue: "ar-SY",
                    translationValue: "ar",
                    voice: null
                },
                {
                    name: "Arabic (United Arab Emirates)",
                    transcriptionValue: "ar-AE",
                    translationValue: "ar",
                    voice: null
                },
                {
                    name: "Bulgarian",
                    transcriptionValue: "bg-BG",
                    translationValue: "bg",
                    voice: "bg-BG-BorislavNeural"
                },
                {
                    name: "Catalan",
                    transcriptionValue: "ca-ES",
                    translationValue: "ca",
                    voice: "ca-ES-EnricNeural"
                },
                {
                    name: "Chinese (Cantonese, Traditional)",
                    transcriptionValue: "zh-HK",
                    translationValue: "zh-Hant",
                    voice: "zh-HK-Danny"
                },
                {
                    name: "Chinese (Mandarin, Simplified)",
                    transcriptionValue: "zh-CN",
                    translationValue: "zh-Hans",
                    voice: "zh-CN-Kangkang"
                },
                {
                    name: "Croatian",
                    transcriptionValue: "hr-HR",
                    translationValue: "hr",
                    voice: "hr-HR-SreckoNeural"
                },
                {
                    name: "Czech",
                    transcriptionValue: "cs-CZ",
                    translationValue: "cs",
                    voice: "cs-CZ-AntoninNeural"
                },
                {
                    name: "Danish",
                    transcriptionValue: "da-DK",
                    translationValue: "da",
                    voice: "da-DK-JeppeNeural"
                },
                {
                    name: "Dutch",
                    transcriptionValue: "nl-NL",
                    translationValue: "nl",
                    voice: "nl-NL-MaartenNeural"
                },
                {
                    name: "English",
                    transcriptionValue: "en-US",
                    translationValue: "en",
                    voice: "en-US-GuyNeural"
                },
                {
                    name: "Estonian",
                    transcriptionValue: "et-EE",
                    translationValue: "et",
                    voice: null
                },
                {
                    name: "French",
                    transcriptionValue: "fr-FR",
                    translationValue: "fr",
                    voice: "fr-FR-HenriNeural"
                },
                {
                    name: "Finnish",
                    transcriptionValue: "fi-FI",
                    translationValue: "fi",
                    voice: null
                },
                {
                    name: "German",
                    transcriptionValue: "de-DE",
                    translationValue: "de",
                    voice: "de-DE-ConradNeural"
                },
                {
                    name: "Greek",
                    transcriptionValue: "el-GR",
                    translationValue: "el",
                    voice: "el-GR-NestorasNeural"
                },
                {
                    name: "Gujarati (India)",
                    transcriptionValue: "gu-IN",
                    translationValue: "gu",
                    voice: null
                },
                {
                    name: "Hindi",
                    transcriptionValue: "hi-IN",
                    translationValue: "hi",
                    voice: null
                },
                {
                    name: "Hungarian",
                    transcriptionValue: "hu-HU",
                    translationValue: "hu",
                    voice: "hu-HU-TamasNeural"
                },
                {
                    name: "Irish",
                    transcriptionValue: "ga-IE",
                    translationValue: "ga",
                    voice: null
                },
                {
                    name: "Italian",
                    transcriptionValue: "it-IT",
                    translationValue: "it",
                    voice: "it-IT-DiegoNeural"
                },
                {
                    name: "Japanese",
                    transcriptionValue: "ja-JP",
                    translationValue: "ja",
                    voice: "ja-JP-KeitaNeural"
                },
                {
                    name: "Korean",
                    transcriptionValue: "ko-KR",
                    translationValue: "ko",
                    voice: "ko-KR-InJoonNeural"
                },
                {
                    name: "Latvian",
                    transcriptionValue: "lv-LV",
                    translationValue: "lv",
                    voice: null
                },
                {
                    name: "Lithuanian",
                    transcriptionValue: "lt-LT",
                    translationValue: "lt",
                    voice: null
                },
                {
                    name: "Maltese",
                    transcriptionValue: "mt-MT",
                    translationValue: "mt",
                    voice: null
                },
                {
                    name: "Marathi (India)",
                    transcriptionValue: "mr-IN",
                    translationValue: "mr",
                    voice: null
                },
                {
                    name: "Norwegian",
                    transcriptionValue: "nb-NO",
                    translationValue: "nb",
                    voice: "nb-NO-FinnNeural"
                },
                {
                    name: "Polish",
                    transcriptionValue: "pl-PL",
                    translationValue: "pl",
                    voice: "pl-PL-MarekNeural"
                },
                {
                    name: "Portuguese",
                    transcriptionValue: "pt-PT",
                    translationValue: "pt",
                    voice: "pt-PT-DuarteNeural"
                },
                {
                    name: "Romanian",
                    transcriptionValue: "ro-RO",
                    translationValue: "ro",
                    voice: "ro-RO-EmilNeural"
                },
                {
                    name: "Russian",
                    transcriptionValue: "ru-RU",
                    translationValue: "ru",
                    voice: "ru-RU-DmitryNeural"
                },
                {
                    name: "Slovak",
                    transcriptionValue: "sk-SK",
                    translationValue: "sk",
                    voice: "sk-SK-LukasNeural"
                },
                {
                    name: "Slovenian",
                    transcriptionValue: "sl-SI",
                    translationValue: "sl",
                    voice: "sl-SI-RokNeural"
                },
                {
                    name: "Spanish",
                    transcriptionValue: "es-ES",
                    translationValue: "es",
                    voice: "es-ES-AlvaroNeural"
                },    
                {
                    name: "Swedish",
                    transcriptionValue: "sv-SE",
                    translationValue: "sv",
                    voice: "sv-SE-MattiasNeural"
                },
                {
                    name: "Tamil (India)",
                    transcriptionValue: "ta-IN",
                    translationValue: "ta",
                    voice: "ta-IN-ValluvarNeural"
                },
                {
                    name: "Telugu (India)",
                    transcriptionValue: "te-IN",
                    translationValue: "te",
                    voice: "te-IN-MohanNeural"
                }, 
                {
                    name: "Thai",
                    transcriptionValue: "th-TH",
                    translationValue: "th",
                    voice: "th-TH-NiwatNeural"
                }, 
                {
                    name: "Turkish",
                    transcriptionValue: "tr-TR",
                    translationValue: "tr",
                    voice: "tr-TR-AhmetNeural"
                }
            ],
            selectedLanguage: "default",
            ttsEnabled: true
        };
    }

    handleChange(e) {
        this.setState({ [e.target.name] : e.target.value });

        if (this.hasTTSFeature(e.target.value)) {
            this.setState({ttsEnabled: true});
        }
        else {
            this.setState({ttsEnabled: false});
        }
    }

    getTranscriptionValue() {
        for (var i = 0; i < this.state.languages.length; i++) {
            const l = this.state.languages[i];
            if (l.name === this.state.selectedLanguage) {
                return l.transcriptionValue;
            }
        }

        // Not found
        return null;
    }

    getTranslationValue() {
        for (var i = 0; i < this.state.languages.length; i++) {
            const l = this.state.languages[i];
            if (l.name === this.state.selectedLanguage) {
                return l.translationValue;
            }
        }

        // Not found
        return null;
    }

    getVoiceValue() {
        for (var i = 0; i < this.state.languages.length; i++) {
            const l = this.state.languages[i];
            if (l.name === this.state.selectedLanguage) {
                return l.voice;
            }
        }

        // Not found
        return null;
    }

    getLanguageName() {
        for (var i = 0; i < this.state.languages.length; i++) {
            const l = this.state.languages[i];
            if (l.name === this.state.selectedLanguage) {
                return l.name;
            }
        }

        // Not found
        return null;
    }

    hasTTSFeature(language) {
        for (var i = 0; i < this.state.languages.length; i++) {
            const l = this.state.languages[i];
            if (l.name === language) {
                return l.voice !== null;
            }
        }

        return false;
    }

    render() {
        return (
            <Col>
                <Form.Control 
                    as="select" 
                    name="selectedLanguage"
                    onChange={this.handleChange.bind(this)}
                    value={this.state.selectedLanguage}
                    className={this.props.className}
                    custom 
                    disabled={this.props.disabled}
                    isInvalid={!this.state.ttsEnabled}>
                        <option value="default" key="default" disabled>Select a language ...</option>
                        {
                            this.state.languages.map(function(l) {
                                return  <option value={l.name} key={l.name}>{l.name}</option>
                            })
                        }    
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    {this.state.selectedLanguage} doesn't have the text-to-speech feature.
                </Form.Control.Feedback>
            </Col>
        )
    }
}