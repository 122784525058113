import React from "react";
import Alert from "react-bootstrap/Alert";
import Image from "react-bootstrap/Image";

export default class About extends React.Component {
    render() {
        return (
            <div className="HomeChat">
                <div className="landerChat">
                    <Alert variant="warning">
                        <p>This application is intented to be used with a stereo input microphone like the <Alert.Link href="https://www.ntt-at.com/product/imic/" target="_blank">NTT FR-1000</Alert.Link>.</p>
                        <p>Configuration for 180 degrees sound collect (two directions: +90° and -90°): </p>
                        <p>All the DIP switches are on the <b>ON</b> position.</p>
                        <p/>
                        <Image src="microphone.png" rounded alt="" />
                    </Alert>
                </div>
                </div>
        )
    }
}