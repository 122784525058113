export function getStream(stream, channel) {
    var ctx = new AudioContext();
    var source = ctx.createMediaStreamSource(stream);
    source.channelInterpretation = "discrete";
    var splitter = ctx.createChannelSplitter(2);
    source.connect(splitter);
    var merger = ctx.createChannelMerger(2);

    // Reduce sound in one channel to isolate the other one
    var leftGain = ctx.createGain();
    var rightGain = ctx.createGain();
    if (channel === Channels.RIGHT) {
        // Reduce the volume of the left channel only
        leftGain.gain.value = 0;
        rightGain.gain.value = 1;
    } 
    else {
        // Reduce the volume of the right channel only
        leftGain.gain.value = 1;
        rightGain.gain.value = 0;
    }

    splitter.connect(leftGain, 0);
    splitter.connect(rightGain, 1);

    leftGain.connect(merger, 0, 0);
    rightGain.connect(merger, 0, 1);

    var dest = ctx.createMediaStreamDestination();
    merger.connect(dest);

    return [dest.stream, ctx]
};

export const Channels = Object.freeze({
    "LEFT": 1,
    "RIGHT": 2
})