import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { PlayFill, StopFill, Download } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LanguageSelector from "../components/LanguageSelector";
import ChatBox from "../components/ChatBox";
import Emitter from "../components/Emitter";
import { Channels } from "../speech/Microphone";
import Transcription from "../speech/Transcription";
import Translation from "../speech/Translation";
import Transcript from "../utils/Transcript";
import TransactionCounter from "../utils/TransactionCounter";

import "./Chat.css";

export default class Chat extends React.Component {
    constructor(props) {
        super(props);

        this.leftLanguageSelector = React.createRef();
        this.rightLanguageSelector = React.createRef();

        this.transactionCounter = new TransactionCounter();

        this.leftTranscription = new Transcription('START_TRANSCRIPTION_1', this.addLeftSpeakerMessage.bind(this), this.transactionCounter);
        this.leftTranslation = new Translation('START_TRANSLATION_1', this.addRightTranslationMessage.bind(this), this.transactionCounter);

        this.rightTranscription = new Transcription('START_TRANSCRIPTION_2', this.addRightSpeakerMessage.bind(this), this.transactionCounter);
        this.rightTranslation = new Translation('START_TRANSLATION_2', this.addLeftTranslationMessage.bind(this), this.transactionCounter);

        this.state = {
            started: false,
            show: true,
            rightSpeaker: "",
            leftSpeaker: "",
            rightMessages: [],
            leftMessages: []
        }
    }

    addSpeakerMessage(author, message, time, channel) {
        if (channel === Channels.LEFT) {
            this.setState(({ leftMessages }) => ({
                leftMessages: [...leftMessages, {
                    author: author,
                    text: message,
                    time: time,
                    position: 'left',
                    language: this.leftLanguageSelector.current.getTranscriptionValue(),
                    voice: this.leftLanguageSelector.current.getVoiceValue()
                }]
            }));
        } else {
            this.setState(({ rightMessages }) => ({
                rightMessages: [...rightMessages, {
                    author: author,
                    text: message,
                    time: time,
                    position: 'right',
                    language: this.rightLanguageSelector.current.getTranscriptionValue(),
                    voice: this.rightLanguageSelector.current.getVoiceValue()
                }]
            }));
        }

        this.adjustScrollbar();
    }

    addTranslationMessage(author, message, time, channel) {
        if (channel === Channels.LEFT) {
            this.setState(({ leftMessages }) => ({
                leftMessages: [...leftMessages, {
                    author: author,
                    text: message,
                    time: time,
                    position: 'right',
                    language: this.leftLanguageSelector.current.getTranscriptionValue(),
                    voice: this.leftLanguageSelector.current.getVoiceValue()
                }]
            }));
        } else {
            this.setState(({ rightMessages }) => ({
                rightMessages: [...rightMessages, {
                    author: author,
                    text: message,
                    time: time,
                    position: 'left',
                    language: this.rightLanguageSelector.current.getTranscriptionValue(),
                    voice: this.rightLanguageSelector.current.getVoiceValue()
                }]
            }));
        }

        this.adjustScrollbar();
    }

    addRightSpeakerMessage(message, time) {
        this.addSpeakerMessage(this.state.rightSpeaker, message, time, Channels.RIGHT);
    }

    addLeftSpeakerMessage(message, time) {
        this.addSpeakerMessage(this.state.leftSpeaker, message, time, Channels.LEFT);
    }

    addRightTranslationMessage(message, time) {
        this.addTranslationMessage(this.state.leftSpeaker, message, time, Channels.RIGHT);
    }

    addLeftTranslationMessage(message, time) {
        this.addTranslationMessage(this.state.rightSpeaker, message, time, Channels.LEFT);
    }

    adjustScrollbar() {
        var elem = document.getElementById('leftChat');
        elem.scrollTop = elem.scrollHeight;
        elem = document.getElementById('rightChat');
        elem.scrollTop = elem.scrollHeight;
    }

    async handleOnStart(event) {
        event.preventDefault();

        this.transactionCounter.resetCounter();

        if (this.leftLanguageSelector.current.getTranscriptionValue() == null ||
            this.rightLanguageSelector.current.getTranscriptionValue() == null) {
            toast.error("Please select 2 languages to start", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
        else {
            // Start the different objects used to transcript and translate
            this.leftTranscription.start();
            this.leftTranslation.start();

            this.rightTranslation.start();
            this.rightTranscription.start();

            // Reset the messages array to empty
            this.setState({
                rightMessages: [],
                leftMessages: []
            });

            // Left channel speesh + translation
            Emitter.emit('START_TRANSCRIPTION_1', { language: this.leftLanguageSelector.current.getTranscriptionValue(), channel: Channels.RIGHT });
            Emitter.emit('START_TRANSLATION_1', {
                source: this.leftLanguageSelector.current.getTranscriptionValue(),
                target: this.rightLanguageSelector.current.getTranslationValue(),
                channel: Channels.RIGHT
            });

            // Right channel speech + translation
            Emitter.emit('START_TRANSCRIPTION_2', { language: this.rightLanguageSelector.current.getTranscriptionValue(), channel: Channels.LEFT });
            Emitter.emit('START_TRANSLATION_2', {
                source: this.rightLanguageSelector.current.getTranscriptionValue(),
                target: this.leftLanguageSelector.current.getTranslationValue(),
                channel: Channels.LEFT
            });

            this.setState({
                started: true
            });

            toast.success("Successfully started", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    }

    handleOnStop(event) {
        event.preventDefault();

        Emitter.emit('STOP_TRANSCRIPTION');
        Emitter.emit('STOP_TRANSLATION');

        this.setState({
            started: false
        });

        toast.success("Successfully stopped", {
            position: toast.POSITION.BOTTOM_RIGHT
        });

        this.rightTranscription.stop();
        this.leftTranscription.stop();
        this.rightTranslation.stop();
        this.leftTranslation.stop();

        // Transactions count
        // const t = this.transactionCounter.getCounter();
        // const cost = this.transactionCounter.getCostEstimation();

        // toast.success("~ " + t + " transactions for ~ " + cost.toFixed(3) + "€", {
        //     position: toast.POSITION.BOTTOM_RIGHT
        // });
    }

    generateTranscript(event) {
        event.preventDefault();

        const filename = "transcript" + new Date().toISOString() + ".docx";

        var transcript = new Transcript();
        transcript.generate(
            this.leftLanguageSelector.current.getLanguageName(),
            this.rightLanguageSelector.current.getLanguageName(),
            this.state.leftMessages,
            this.state.rightMessages);
        transcript.saveToFile(filename);
    }

    render() {
        return (
            <div className="HomeChat">
                <div className="landerChat">
                    <Form>
                        <Form.Group as={Row} className="justify-content-center" id="speakerGroup">
                            <Col>
                                <Form.Control
                                    type="text"
                                    placeholder="Left speaker name ..."
                                    value={this.state.leftSpeaker}
                                    onChange={e => this.setState({ leftSpeaker: e.target.value })}
                                    disabled={this.state.started} />
                            </Col>
                            <LanguageSelector ref={this.leftLanguageSelector} disabled={this.state.started} />
                            <Col>
                                <Form.Control
                                    type="text"
                                    placeholder="Right speaker name ..."
                                    value={this.state.rightSpeaker}
                                    onChange={e => this.setState({ rightSpeaker: e.target.value })}
                                    disabled={this.state.started} />
                            </Col>
                            <LanguageSelector ref={this.rightLanguageSelector} disabled={this.state.started} />
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col>
                                <ChatBox messages={this.state.leftMessages} id="leftChat" />
                            </Col>
                            <Col>
                                <ChatBox messages={this.state.rightMessages} id="rightChat" />
                            </Col>
                        </Form.Group>
                        {!this.state.started
                            ? <Button variant="outline-success" size="lg" onClick={e => this.handleOnStart(e)} block><PlayFill />Start</Button>
                            : <Button variant="outline-danger" size="lg" onClick={e => this.handleOnStop(e)} block><StopFill />Stop</Button>}
                        <Button
                            variant="outline-warning"
                            size="lg"
                            onClick={(e) => this.generateTranscript(e)}
                            disabled={this.state.leftMessages.length === 0 && this.state.rightMessages.length === 0}
                            block>
                            <Download /> Download Transcript
                        </Button>
                    </Form>
                </div>
            </div>
        );
    }
}