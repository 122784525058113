import React from "react";

import "./Message.css";

export default class Message extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            msg: this.props.message,
            index: this.props.index,
            sameConversation: this.props.sameConversation
        }
    }

    buildSSML() {
        /*
        <speak version="1.0" xmlns="https://www.w3.org/2001/10/synthesis" xml:lang="en-US">
            <voice name="en-GB-George-Apollo">
                When you're on the motorway, it's a good idea to use a sat-nav.
            </voice>
        </speak>
        */
        var xmlDoc = document.implementation.createDocument(null, "speak");
        xmlDoc.getElementsByTagName('speak')[0].setAttribute('version', '1.0');
        xmlDoc.getElementsByTagName('speak')[0].setAttribute('xmlns', "https://www.w3.org/2001/10/synthesis");
        xmlDoc.getElementsByTagName('speak')[0].setAttribute('xml:lang', this.state.msg.language);

        var voiceNode = xmlDoc.createElement('voice')
        voiceNode.setAttribute('name', this.state.msg.voice);
        var textNode = xmlDoc.createTextNode(this.state.msg.text);
        voiceNode.appendChild(textNode);

        xmlDoc.getElementsByTagName('speak')[0].appendChild(voiceNode);

        var serializer = new XMLSerializer();
        var xmlString = serializer.serializeToString(xmlDoc)
        console.log(xmlString);

        return xmlString;
    }

    synthestizeSpeech() {
        var ssml = this.buildSSML();

        const sdk = require("microsoft-cognitiveservices-speech-sdk");
        const speechConfig = sdk.SpeechConfig.fromSubscription(process.env.REACT_APP_API_KEY, process.env.REACT_APP_REGION);
        const audioConfig = sdk.AudioConfig.fromDefaultSpeakerOutput();

        const synthesizer = new sdk.SpeechSynthesizer(speechConfig, audioConfig);
        synthesizer.speakSsmlAsync(
            ssml,
            result => {
                if (result) {
                    synthesizer.close();
                    return result.audioData;
                }
            },
            error => {
                console.log(error);
                synthesizer.close();
            }
        );
    }

    render() {
        return (
            <div className={"row-message row-message-" + this.state.msg.position}>
                {!this.state.sameConversation
                    ?   <div className={"message message-" + this.state.msg.position} onClick={this.state.msg.voice !== null ? () => this.synthestizeSpeech() : null}>
                            <span className="author">{this.state.msg.author}</span> <span className="time">{this.state.msg.time}</span>
                            <p>{this.state.msg.text}</p>
                        </div>
                    :   <div className={"message message-conversation message-" + this.state.msg.position} onClick={this.state.msg.voice !== null ? () => this.synthestizeSpeech() : null}>
                            <p>{this.state.msg.text}</p>
                        </div>
                }
            </div>
        );
    }
}

