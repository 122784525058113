export default class TransactionCounter {
    constructor() {
        this.counter = 0;
    }

    incrementCounter() {
        this.counter++;
    }

    resetCounter() {
        this.counter = 0;
    }

    getCounter() {
        return this.counter;
    }

    getCostEstimation() {
        var unitPrice = 0.004639;

        return this.counter * unitPrice;
    }
}